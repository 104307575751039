import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AlertMessage} from '../messages/model/alert-message';
import {MessageService} from '../messages/service/message.service';
import {faUnlock, faCircle, faAngleDoubleLeft, faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';
import {NAV_LIST} from '../mock/mock-nav-items';
import {User} from '../model/user';
import {UserService} from '../service/user.service';
import {FilingBlank} from './model/filingblank';
import {FormSection} from './model/form-section';
import {OnlineDataEntryService} from './services/online-data-entry.service';
import {FilingData} from './model/filing-data';
import {catchError, finalize} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {QuestionControlService} from './dynamic-forms/question-control-service';
import {ActivatedRoute, Router} from '@angular/router';
import {OnlineSubmissionsStatus} from './model/online-submissions-status';
import { forkJoin, Observable, of } from 'rxjs';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image-more';


@Component({
  selector: 'app-online-data-entry',
  templateUrl: './online-data-entry.component.html',
  styleUrls: ['./online-data-entry.component.css']
})
export class OnlineDataEntryComponent implements OnInit, OnDestroy {
  @ViewChild('mainContainer', {static: true}) mainContainer: ElementRef;
  errorMessage: string | null = null;
  showSummary: boolean = false;
  sidebarCollapsed: boolean = false;
  faUnlock = faUnlock;
  faCircle = faCircle;
  faAngleDoubleLeft = faAngleDoubleLeft;
  faAngleDoubleRight = faAngleDoubleRight;
  title: string;
  filingBlank: FilingBlank;
  filingStatus: string;
  navigationList: { navigationItems: { name: string; target: string; subNav: { name: string; target: string; subNav: any[]; }[]; }[]; };
  loading: boolean;
  user: User;
  schema: string;
  noData: any;
  isValidated = false;
  warningsComment: string;
  filingData: FilingData;
  keysl: any[] = [];
  selectedFormSection: any;
  saveAndValidate: any;
  isSidebarCollapsed = true;
  maxSideBarWidth = '450px';
  minSideBarWidth = '0px';
  allFilingBlank: FilingBlank;
  formSections: FormSection[];
  allSections: any;
  btnClicked: any;
  validateClick = true;
  assignmentKey: any;
  masterFilingBlanks: any;

  constructor(
    private userService: UserService,
    private onlineDataEntryService: OnlineDataEntryService,
    private qcs: QuestionControlService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public elementRef: ElementRef
  ) {
  }

  ngOnInit() {
    this.assignmentKey = this.onlineDataEntryService.assignmentKey;
    if (!this.assignmentKey || !this.assignmentKey.assignmentValue || !this.assignmentKey.assignmentField) {
      this.router.navigate(['schemaSelection']);
      return;
    }
    this.onlineDataEntryService.dataChange.subscribe(ele => this.formSections = ele);
    this.user = this.userService.getStoredUser();
    this.schema = this.user.currentSchema.toLocaleUpperCase();
    this.onlineDataEntryService.schema = this.schema;
    this.setFilingStatus(this.schema);
    this.title = `${this.schema} Data Entry Form - ${this.assignmentKey.assignmentValue}`;
    this.toggleSideBar();
    this.getFilingBlankLayout();
    this.navigationList = NAV_LIST;
    this.onlineDataEntryService.isMasterValueEmpty.subscribe((value: boolean) => {
      if(value) {
        this.combineForkJoin();
      }
    });
    this.onlineDataEntryService.isFileUploaded.subscribe((value: boolean) => {
      if(value) {
        this.goTo('summary');
      }
    });
  }

  public get isFormFormatError() {
    return this.onlineDataEntryService.isFormFormatError;
  }

  ngOnDestroy() {
    this.onlineDataEntryService.isFileUploaded.next(false);
    this.onlineDataEntryService.postSaveAttachments.next(false);
    this.onlineDataEntryService.assignmentKey = null;
    this.messageService.clear();
  }

  setFilingStatus(schema: string): void {
    this.onlineDataEntryService.getOnlineFilingsByAssignmentKey(this.schema, this.assignmentKey.assignmentField, this.assignmentKey.assignmentValue)
      .subscribe(onlineSubmissions => {
        onlineSubmissions.forEach(submission => {
          const transformedObj = this.onlineDataEntryService.transformStatus(submission);
          this.filingStatus = transformedObj.status;
        });
      });

  }

  private filingDataOfAllSections(filingBlank: FilingBlank): any {
    let data: any = {};
    let recursiveFunction = (sections: FormSection[]) => {
      sections.forEach((section: FormSection) => {
        let form = this.qcs.generateForm(section, false, false, false);
        let formData = (form.value);
        let key = Object.keys(formData)[0];
        data[key] = formData[key];
        recursiveFunction(section.sections);
      });
    };
    recursiveFunction(filingBlank.sections);
    return data;
  }


  // To get All the Sections of the Data Entry form
  getAllSections() {
    this.loading = true;
    this.onlineDataEntryService.getAllDataEntries(this.user.currentSchema.toLocaleUpperCase(), this.onlineDataEntryService.assignmentKey)
      .subscribe(
        filingBlank => {
          this.allSections = filingBlank;
          this.onlineDataEntryService.setSummaryPageSections(this.allSections);
          this.onlineDataEntryService.masterAttachments = filingBlank.attachments;
          this.onlineDataEntryService.postSaveAttachments.next(true);
          this.loading = false;
          this.showSummary = true;
          // this.showSummaryMessages();
        }
      );
  }

  isProcessing() {
    return this.filingStatus === OnlineSubmissionsStatus.PROCESSING;
  }

  isUserAdmin() {
    return this.userService.canViewFileAdminLink(this.user);
  }

  // Save On side nave Summary
  openSideNavSummary(section) {
    this.goTo(section);
  }

  getFilingBlankLayout() {
    this.loading = true;
    this.onlineDataEntryService.getFilingBlankLayout(this.user.currentSchema).subscribe(
      (res) => {
        if (res.status === 200) {
          this.allFilingBlank = res.body;
          this.onlineDataEntryService.setAllSections(this.allFilingBlank);
          this.getSeperateSections(this.allFilingBlank.sections);
        } else {
          this.allFilingBlank = null;
        }
        this.onlineDataEntryService.setFilingBlank(this.allFilingBlank);
        this.onlineDataEntryService.setOriginalFilingBlank(this.allFilingBlank);
        this.combineForkJoin(this.keysl[0]);
      }, (err) => {
        this.loading = false;
      }
    );
  }

  combineForkJoin(nextBlank?) {
    this.isValidated = false;
    this.errorMessage = null;
    let targetBlank = nextBlank || this.onlineDataEntryService.activeSection;
    this.loading = true;
    window.scroll(0, 0);

    const getAllDataEntries$: Observable<any> = this.onlineDataEntryService.getFilingBlanks(this.schema, targetBlank, this.assignmentKey);
    const getFilingBlanks$: Observable<any> = this.onlineDataEntryService.getFilingBlanks(this.schema, '', this.assignmentKey);

    forkJoin({
      allDataEntries: getAllDataEntries$,
      filingBlanks: getFilingBlanks$
    }).pipe(
      catchError(error => {
        console.error('Error:', error);
        return of(null);
      }),
      finalize(() => {
        this.loading = false;
      })
    ).subscribe({
      next: (results: { allDataEntries: any, filingBlanks: any }) => {
        this.masterFiling(results.filingBlanks);
        this.goToFiling(results.allDataEntries, targetBlank);
      },
      error: (error) => {
        console.error('Error:', error);
      }
    });
  }

  goToFiling(filingBlank, targetBlank) {
    this.onlineDataEntryService.activeNode.next(targetBlank);
    this.onlineDataEntryService.dataChanged = false;
    if (filingBlank && filingBlank !== null) {
      this.filingBlank = filingBlank;
      this.onlineDataEntryService.setTargetFilingBlank(this.filingBlank);
      this.onlineDataEntryService.activeSection = targetBlank;
      this.onlineDataEntryService.masterAttachments = filingBlank.attachments;
    }
  }

  masterFiling(filingBlank) {
    if (!filingBlank || filingBlank === null) return;
    let data = this.filingDataOfAllSections(filingBlank);
    for (const key in data) {
        if (!data.hasOwnProperty(key)) continue;
        const innerData = data[key];
        for (const subKey in innerData) {
            if (!innerData.hasOwnProperty(subKey)) continue;
            const properties = innerData[subKey];
            if ("Disabled" in properties && properties["Disabled"] === "") {
                delete data[key];
                break;
            }
        }
    }
    this.onlineDataEntryService.masterSelectedValues = data;
}

  getSeperateSections(sections: FormSection[]) {
    let sec: FormSection[];
    sections.forEach(x => {
      this.keysl.push(x.key);
      if (x.sections.length !== 0) {
        this.getSeperateSections(x.sections);
      }
    });
  }

  selectedFormSectionHandler(event) {
    this.showSummary = false;
    this.selectedFormSection = event;
    this.combineForkJoin(event.key);
  }

  saveAndValidateHandler(event) {
    this.filingData = event;
    this.onValidate(this.filingData);
  }

  saveHandler(event: FilingData) {
    this.filingData = event;
    this.onSave(event);
    this.btnClicked = '';
  }

  goTo(section: string): void {
    this.btnClicked = section;
    this.validateClick = true;
    if (this.onlineDataEntryService.dataChanged) {
      this.onSave(this.onlineDataEntryService.dataUpdates);
    } else if (section === 'summary') {
      this.messageService.clear();
      this.getAllSections();
      this.isValidated = false;
      this.onlineDataEntryService.activeNode.next('');
    }
    if (!this.onlineDataEntryService.dataChanged) {
    if (section === 'next') {
      this.combineForkJoin(this.filingBlank.next);
    } else if (section === 'previous') {
      this.combineForkJoin(this.filingBlank.previous);
    }
  }
  }

  // Moving previous from the Summary page
  movePrevious() {
    this.combineForkJoin(this.allSections.sections[this.allSections.sections.length - 1].key);
    this.showSummary = false;
  }

  submitFiling() {
    this.messageService.clear();
    let data = this.filingDataOfAllSections(this.allSections);
    this.loading = true;
    window.scroll(0, 0);
    this.onlineDataEntryService.postFilingData(this.schema, {data}, this.assignmentKey).pipe(
      finalize(() => this.loading = false)
    ).subscribe(
      (res: any) => {
        this.onlineDataEntryService.dataChanged = false;
        if (res && res !== null) {
          if (res.filingBlank) {
            this.filingBlank = res.filingBlank;
            this.onlineDataEntryService.setTargetFilingBlank(this.filingBlank);
            this.reDirectToUserFilingDashboard();
          }
        }
      }, (error: HttpErrorResponse) => {
        if (error.error.status === 500) {
          this.errorMessage = 'We have encountered technical difficulties, please try again later or contact the NAIC Help Desk';
        }
        if (error.error.notSubmittedAlreadyProcessing) {
          this.errorMessage = 'notSubmittedAlreadyProcessing';
        } else if (error.error.notSubmittedHasErrors) {
          this.errorMessage = 'notSubmittedHasErrors';
        } else if (error.error.notSubmittedNoSubmissionKeyInFilingData) {
          this.errorMessage = 'Submission unable to be submitted contact datacall administrator.';
        }
      }
    );
  }

  reDirectToUserFilingDashboard() {
    this.onlineDataEntryService.isSubmitted.next(true);
    this.router.navigate(['user-filing', this.schema]);
  }

  submitHandler() {
    if (this.onlineDataEntryService.isValidated && this.totalErrors() === 0) {
      this.submitFiling();
    } else {
      this.messageService.add(new AlertMessage('Please validate the form in order to submit', 'error'));
    }
  }

  showSummaryMessages() {
    let messages = (this.onlineDataEntryService.getAllMessagesOfSections(this.allSections));
    const allSectionWarnings = messages.filter(ele => ele.warning).length;
    const allSectionsErrors = messages.filter(ele => ele.error).length;
    let message = `Filing has been validated with ${this.showSummary ? allSectionsErrors : this.totalErrors()} total errors and ${this.showSummary ? allSectionWarnings : this.totalWarnings()}  total warnings`;
    const alertType = allSectionsErrors > 0 ? 'danger' : allSectionWarnings > 0 ? 'warning' : 'success';
    this.messageService.add(new AlertMessage(message, alertType));
    messages.filter((value, index, self) =>
      index === self.findIndex((t) => (
          t.message === value.message
        )
      )).forEach(ele => {
      if (ele.error) {
        this.messageService.add(new AlertMessage(ele.message, 'danger'));
      } else {
        this.messageService.add(new AlertMessage(ele.message, 'warning'));
      }
    });

  }

  onValidate(filingData: FilingData) {
    this.messageService.clear();
    this.onlineDataEntryService.sectionErrors = [];
    this.onlineDataEntryService.sectionWarnings = [];
    let targetBlank = this.showSummary ? '' : this.onlineDataEntryService.activeSection;
    this.loading = true;
    window.scroll(0, 0);
    this.onlineDataEntryService.validateFilingData(this.schema, this.showSummary ?
      this.filingDataOfAllSections(this.allSections) : filingData, this.assignmentKey, targetBlank).pipe(
      finalize(() => this.loading = false)
    )
      .subscribe(
        filingBlank => {
          this.onlineDataEntryService.activeNode.next(targetBlank);
          if (filingBlank && filingBlank !== null) {
            this.filingBlank = filingBlank;
            this.allSections = this.filingBlank;
            this.onlineDataEntryService.setTargetFilingBlank(this.filingBlank);
            const sections = this.filingBlank.sections;
            if (!this.showSummary) {
              const errorsMessage = `has ${this.totalErrors()} total errors`;
              const warningMessage = `has ${this.totalWarnings()} total warnings`;
              let message = '';
              message = `Section has been validated. ${this.filingBlank.sections[0].name}  ${errorsMessage} and ${warningMessage}`;
              const alertType = this.hasErrors() ? 'danger' : this.hasWarnings() ? 'warning' : 'success';
              this.messageService.add(new AlertMessage(message, alertType));


            } else {
              this.validateClick = false;
              this.isValidated = !this.hasErrors();
              this.onlineDataEntryService.isValidated = this.isValidated;
              this.allSections = this.filingBlank;
              this.showSummaryMessages();
            }
            this.combineForkJoin();
          }
          if (!this.showSummary) {
            this.onlineDataEntryService.activeSection = targetBlank;
          }
          this.onlineDataEntryService.dataChanged = false;
          this.onlineDataEntryService.dataUpdates = {};

        }
      );
  }

  onSave(filingData: FilingData) {
    this.messageService.clear();
    this.onlineDataEntryService.sectionErrors = [];
    this.onlineDataEntryService.sectionWarnings = [];
    let targetBlank = this.onlineDataEntryService.activeSection;
    this.loading = true;
    window.scroll(0, 0);
    this.onlineDataEntryService.saveFilingData(this.schema, filingData, this.assignmentKey, targetBlank).pipe(
      finalize(() => this.loading = false)
    ).subscribe(
      filingBlank => {
        this.filingBlank = filingBlank;
        this.onlineDataEntryService.activeNode.next(targetBlank);
        if (this.btnClicked === 'next') {
          this.combineForkJoin(this.filingBlank.next);
        } else if (this.btnClicked === 'previous') {
          this.combineForkJoin(this.filingBlank.previous);
        } else if (this.btnClicked === 'summary') {
          // this.messageService.add(new AlertMessage('Please Save and Validate before Submit', 'info'));
          this.onlineDataEntryService.activeNode.next('');
          this.getAllSections();
        }
        this.onlineDataEntryService.dataChanged = false;
        this.messageService.add(new AlertMessage(`${this.filingBlank.sections[0].name} Form Saved Successfully`, 'success'));
      }
    );
  }

  hasErrors(): boolean {
    return this.onlineDataEntryService.totalErrors(this.filingBlank) > 0;
  }

  hasWarnings(): boolean {
    return this.onlineDataEntryService.totalWarnings(this.filingBlank) > 0;
  }

  totalErrors(): number {
    return this.onlineDataEntryService.totalErrors(this.filingBlank);
  }

  totalWarnings(): number {
    return this.onlineDataEntryService.totalWarnings(this.filingBlank);
  }

  getInputStyle(): string[] {
    if (this.sidebarCollapsed) {
      return ['col-12', 'ml-sm-auto', 'd-print-none'];
    } else {
      return ['col-md-9', 'ml-sm-auto', 'col-lg-10', 'd-print-none'];
    }
  }

  toggleSideBar() {
    if (this.isSidebarCollapsed) {
      document.getElementById('sideNav').style.width = this.maxSideBarWidth;
      document.getElementById('main').style.marginLeft = this.maxSideBarWidth;
      document.getElementById('toggleBtn').style.marginLeft = this.maxSideBarWidth;
    } else {
      document.getElementById('sideNav').style.width = this.minSideBarWidth;
      document.getElementById('main').style.marginLeft = this.minSideBarWidth;
      document.getElementById('toggleBtn').style.marginLeft = this.minSideBarWidth;
    }
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
    document.getElementById('toggleBtn').blur();
  }

  deleteHandler(event) {
    this.loading = true;
    this.messageService.clear();
    this.onlineDataEntryService.deleteAttachment(this.schema, event, this.assignmentKey).subscribe(res => {
      this.messageService.add(new AlertMessage(`${event.originalFileName} Deleted Successfully`, 'success'));
      window.scroll(0,0);
      this.mainContainer.nativeElement.scrollTop = 0;
      setTimeout(() => {
        this.goTo('summary');
      }, 1000);
    });
  }

  downloadAttachment(event) {
    this.loading = true;
    this.onlineDataEntryService.getDownloadUrlfromS3(this.user, event.fileName).subscribe(response => {
      if(response) {
        this.loading = false;
        const presignedUrl = response.uploadURL;
        this.handleS3Download(presignedUrl);
      } else {
        this.loading = false;
        window.scroll(0,0);
        this.mainContainer.nativeElement.scrollTop = 0;
      }
    });
  }

  handleS3Download(presignedUrl: string) {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = presignedUrl;
    hiddenElement.target = '_blank';
    hiddenElement.click();
    hiddenElement.remove();
    window.scroll(0,0);
    this.mainContainer.nativeElement.scrollTop = 0;
  }

  async downloadPDF() {
    let title = `${this.user.currentSchema.toUpperCase()} Data Entry Form`;
    let summaryPageElement = this.elementRef.nativeElement.querySelector('#summaryPage');
  
    if (!summaryPageElement) {
      console.error('Element with ID summaryPage not found');
      return;
    }
  
    // Store the original dropdown elements
    const dropdowns = summaryPageElement.querySelectorAll('select');
    const originalDropdowns: { original: HTMLSelectElement, replacement: HTMLElement }[] = [];
  
    dropdowns.forEach((dropdown: HTMLSelectElement) => {
      const selectedValue = dropdown.value;
  
      // Create a span to replace the dropdown
      const selectedOption = document.createElement('span');
      selectedOption.textContent = selectedValue;
  
      // Replace the dropdown with the span and store the original dropdown
      dropdown.parentNode.replaceChild(selectedOption, dropdown);
      originalDropdowns.push({ original: dropdown, replacement: selectedOption });
    });
  
    try {
      // Generate the image and PDF
      const imgData = await domtoimage.toPng(summaryPageElement);
  
      let pdfWidth = 210; // A4 width in mm
      let elementWidth = summaryPageElement.offsetWidth;
      let elementHeight = summaryPageElement.offsetHeight;
  
      let scaleFactor = pdfWidth / elementWidth;
      let pdfHeight = elementHeight * scaleFactor;
  
      let pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight + 20]);
  
      pdf.setFontSize(16);
      pdf.text(title, pdfWidth / 2, 10, { align: 'center' });
  
      pdf.addImage(imgData, 'PNG', 0, 20, pdfWidth, pdfHeight);
  
      pdf.save(`${title}.pdf`);
    } catch (error) {
      console.error('Error generating image for PDF:', error);
    } finally {
      // Restore the original dropdown elements after the PDF download
      originalDropdowns.forEach(({ original, replacement }) => {
        replacement.parentNode.replaceChild(original, replacement);
      });
    }
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { User } from "../../../model/user";
import { FormSection } from "../../model/form-section";
import { DependenciesService } from "../services/dependencies.service";
import { FormBuilderService } from "../../services/form-builder.service";
import { MessageService } from "../../../messages/service/message.service";
import { UserService } from "../../../service/user.service";

@Component({
  selector: "app-set-dependencies-select-questions",
  templateUrl: "./set-dependencies-select-questions.component.html"
})
export class SetDependenciesSelectQuestionsComponent implements OnInit {
  @Input() section: FormSection;
  @Input() parentId: string;
  @Input() index: number;
  @Input() filteredQuestions: any;
  @Input() isSectionVisible: boolean;
  @Output() isDependencyQuestionClicked = new EventEmitter<boolean>();

  sectionForm: FormGroup;
  path: any;
  inputLabels: any[] = [];
  sectionHeaders: any = {};
  updatedModel: any;

  user: User;
  orderedSection: FormSection;
  isValidSection: boolean;

  constructor(
    private dependenciesService: DependenciesService,
    private formBuilderService: FormBuilderService,
    private userService: UserService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    let updatedSections: FormSection;
    updatedSections = JSON.parse(JSON.stringify(this.section));
    this.getOrderOfSectionsAndFilterEnumFieldValues(this.section);
    this.sectionForm = this.dependenciesService.generateForm(this.section);
    this.dependenciesService.isQuestionSelected = false;
    this.messageService.clear();
  }

  getSectionId(): string {
    let sectionId = "";
    if (this.parentId) {
      sectionId = `${this.parentId}-${this.section.key}`;
    } else {
      sectionId = this.section.key;
    }
    return sectionId;
  }

  getOrderOfSectionsAndFilterEnumFieldValues(section: FormSection) {
    let sectionName = section.key;
    this.updatedModel = this.formBuilderService.setFormData(section);
    this.updatedModel = this.addDependentHeaders(this.updatedModel);
    this.updatedModel = this.updatedModel.filter(x => x.label === '' || this.filteredQuestions.some(y => y.question === x.text && y.section === sectionName));
    this.updatedModel = this.removeNonDependentOrders(this.updatedModel);
    this.filteredQuestions.forEach(ques => {
      this.updatedModel.forEach(model => {
        if(model.key === ques.questionKey) {
          model['primaryKeyCount'] = ques.primaryKeyCount;
          model['associatedRuleCount'] = ques.associatedRuleCount;
          model['isDependent'] = ques.isDependent;
        }
      });
    });
    this.isSectionVisible = this.updatedModel.some((x) => Number(x.label) > 0);
  }  

  addDependentHeaders(formData: Array<any>): Array<any> {
    formData = formData.map(item => {
      return {
        ...item,
        type: item.key === "" ? "heading" : "question",
      };
    });
    let headingOrders: number[] = [];
  
    // Iterate through the array to add dependentHeaders field
    formData.forEach((item, index) => {
      if (item.type === "heading") {
        // If heading, update headingOrders array
        headingOrders.push(item.order);
      } else if (item.type === "question") {
        // If question, add dependentHeaders field with previous heading orders
        item.dependentHeaders = [...headingOrders];
        // Clear headingOrders array only if the next item is not a question
        if (index + 1 < formData.length && formData[index + 1].type !== "question") {
          headingOrders = [];
        }
      }
    });
  
    return formData;
  }

  removeNonDependentOrders(items: any[]) {
    let allHeadings: any[] = [];
    items.forEach(ele => {
      if(ele.type === 'question') {
        allHeadings.push(ele.dependentHeaders);
      }
    });
    return items.filter((item) => {
      if (item.type === "heading") {
          return allHeadings.flat().includes(item.order);
      }
      return true;
  });
  }

  goToSelectedQuestion(question, key, sectionKey) {
    this.dependenciesService.isDependencyQuestionClicked.next(true);
    this.dependenciesService.isSelectQuestionsVisible.next(false);
    this.dependenciesService.selectedQuestion = question;
    this.dependenciesService.selectedQuestionKey = key;
    this.dependenciesService.selectedSectionKey = sectionKey;
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
}

import { Component, OnInit, OnChanges, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TooltipPosition } from '@angular/material';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertMessage } from '../../messages/model/alert-message';
import { ValidationSchema } from '../../file-validation-maintenance/model/validation-schema';
import { FileValidationMaintenanceService } from '../../file-validation-maintenance/services/file-validation-maintenance.service';
import { MessageService } from '../../messages/service/message.service';
import { User } from '../../model/user';
import { UserService } from '../../service/user.service';
import { FilingBlank } from '../model/filingblank';
import { FormSection } from '../model/form-section';
import { FormBuilderService } from '../services/form-builder.service';
import { FieldSelectionComponent } from './field-selection/field-selection.component';
import { UiColumnModel } from './model/ui-column';
import { pairwise, startWith } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-build-edit-form',
  templateUrl: './build-edit-form.component.html',
  styleUrls: ['./build-edit-form.component.css']
})

export class BuildEditFormComponent implements OnInit, OnChanges {
  @Input() selectedFormSection: FormSection;
  @Output() onHide = new EventEmitter<void>();
  title: string;
  name: string;
  user: User;
  schema: string;
  loading: boolean = false;
  filingBlank: any;
  disableIfRdcAdmin: boolean;
  modalSubscriptions: Subscription[] = [];
  bsModalRef: BsModalRef;

  inputsForm: FormGroup;
  inputs: string[];
  columnArray: UiColumnModel[];
  columnsForm: FormGroup;
  addForm: FormGroup;
  tooltipPosition: TooltipPosition = 'above';
  validationSchema: ValidationSchema;
  fieldNames: any[];
  ti: any;
  orderNumber: number;
  isPasteSectionEnabled: boolean = false;
  isCopySectionEnabled: boolean = false;
  menuStates: boolean[] = [];
  isTitleVisible: boolean = true;
  isColumnError: boolean = false;

  constructor(
    private userService: UserService,
    public formBuilderService: FormBuilderService,
    private modalService: BsModalService,
    private messageService: MessageService,
    private _fb: FormBuilder,
    private schemaMaintenanceService: FileValidationMaintenanceService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
     this.user = this.userService.getStoredUser();
    this.schema = this.user.currentSchema.toLocaleUpperCase();
    this.disableIfRdcAdmin = this.userService.getStoredUser().isRdcAdmin;
    this.title = `Build/Edit Form`;
    this.name = this.formBuilderService.getClickedName();
    this.getFilingBlankLayout();
  }

  ngOnChanges() {
    if (this.formSection !== undefined) { this.buildForm()};
    this.isTitleVisible = this.formBuilderService.isTitleVisible; 
    this.disableIfRdcAdmin = this.userService.getStoredUser().isRdcAdmin;
    if(this.formBuilderService.isDeleteConfirmed.getValue()) { this.saveFilingBlank() };
  }

  get formSection() {
    return this.selectedFormSection;
  }

  get rows() {
    return this.addForm.get('rows') as FormArray;
  }

  buildForm() {
    this.isCopyOrPasteEnabled();
    this.initializeFormSectionWithNewHeaders();
    let updatedModel = this.formBuilderService.setFormData(this.formSection);
    if (updatedModel.length === 1) {
      this.columnArray = [{label: 'Heading', value: 'heading'}, { label: 'No.', value: 'label' }, { label: 'Question', value: 'text' }, {label: 'order', value: 'order'}, { label: 'Column 1', value: uuidv4() }];
      this.addForm = this._fb.group({
        rows: this._fb.array([this.buildRows(0, 'new_form')])
      });
      this.rows.insert(1, this.buildRows());
      this.orderNumber = Number(this.rows.at(0).get('order').value.replace(/-/g, ""));
      this.setOrderNumber(this.orderNumber);
    } else {
      this.columnArray = [{label: 'Heading', value: 'heading'},{ label: 'No.', value: 'label' }, { label: 'Question', value: 'text' }];
      let keys = Object.entries(updatedModel[0]).filter(([key, value]) => key !== 'label').filter(([key, value]) => key !== 'text').filter(([key, value]) => key !== 'heading').filter(([key, value]) => key !== 'key');
      keys.forEach(([key, value], index) => {
        if(key === 'order') {
        let addToColumnArray = { label: `Column ${index + 1}`, value: key }
        this.columnArray.push(addToColumnArray);
        }
      });
      let inputObjs = this.formBuilderService.inputObjs;
      inputObjs.forEach(obj => {
        let addToColumnArray = { label: obj.label, value: obj.key};
        this.columnArray.push(addToColumnArray);
      })
      this.columnArray.sort((a, b) => {
        let indexA = inputObjs.findIndex(obj => obj.key === a.value);
        let indexB = inputObjs.findIndex(obj => obj.key === b.value);
        return indexA - indexB;
      });
      this.addForm = this._fb.group({
        rows: this.setControls(updatedModel, this.columnArray)
      });
      this.orderNumber = Number(this.rows.at(0).get('order').value.replace(/-/g, ""));
      this.setOrderNumber(this.orderNumber);
    }
    this.valueChanges();
  }

  initializeFormSectionWithNewHeaders() {
    if(isNullOrUndefined(this.selectedFormSection.headings) || this.selectedFormSection.headings.length === 0) {
      this.selectedFormSection.headings = [{order: 1, heading: null}];
      if(!isNullOrUndefined(this.selectedFormSection.questions) || this.selectedFormSection.questions.length !== 0) {
        let count = 1;
        this.selectedFormSection.questions.forEach(x => {
          if(x.label === String(count)) {
            x.order = count +1;
            count ++;
          }
        });
      }
    }
  }

  valueChanges() {
   this.addForm.valueChanges
      .pipe(startWith(null as string), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        this.formBuilderService.isFormUpdated = true;
        this.formBuilderService.disableDependencyPage.next(true);
        this.isCopyOrPasteEnabled();
      });
  }

  setControls(formSection: any[], columnArray) {
    let control = new FormArray([]);
    formSection.forEach((value, index) => {
      control.push(this._fb.group({}));
      for (let input of columnArray) {
        if(!isNullOrUndefined(value[`${input.value}`])) {
        (control.at(index) as FormGroup).addControl(`${input.value}`, new FormControl(`${value[`${input.value}`]}`));
        } else {
        (control.at(index) as FormGroup).addControl(`${input.value}`, new FormControl(value[`${input.value}`]));
        }
      }
    });
    return control;
  }

  buildRows(count?, hamburgerMenuClick?) {
    let group: any = {};
    this.columnArray.forEach(x => {
      if (hamburgerMenuClick === 'Add_Header' || hamburgerMenuClick === 'new_form') {
      if (x.label === 'No.') {
        group[x.value] = new FormControl('heading');
      } else if (x.label === 'order') {
        group[x.value] = new FormControl(`${count + 1}`)
      } else if (x.label === 'Heading') {
        group[x.value] = new FormControl(``);
      } else if(x.label === 'Question') {
        group[x.value] = new FormControl('');
      } else {
        group[x.value] = new FormControl();
      }
    } else {
      if (x.label === 'No.') {
        group[x.value] = new FormControl(`1`);
      } else if(x.label === 'Heading') {
        group[x.value] = new FormControl('');
      } else {
        group[x.value] = new FormControl();
      }
    }
    });
    return new FormGroup(group);
  }

  setOrderNumber(questionNumber) {
    let count = questionNumber;
    for (let i = 0; i < this.rows.length; i++) {
      this.rows.at(i).get('order').patchValue(count);
      count++;
    }
  }

  addColumnsForTable() {
    const uuid = uuidv4();
    for (let i = 0; i < this.rows.length; i++) {
      this.rows.at(i)[uuid] = '';
      (this.rows.at(i) as FormGroup).addControl(uuid, new FormControl(''))
    }
    this.columnArray.length
    let addToColumnArray = { label: '', value: uuid }
    this.columnArray.push(addToColumnArray);
  }

  changeColumnName(index, event) {
    this.messageService.clear();
    this.isColumnError = false;

    const newColumnName = event.target.value.trim();
    let newColumn = cloneDeep(this.columnArray)
    newColumn[index].label = newColumnName;
    const existingColumnNames = newColumn.map(column => column.label);
    const isSameColumnName = existingColumnNames.filter(column => column === newColumnName).length >= 2;

    if (newColumnName === '') {
      this.isColumnError = true;
      this.messageService.add(new AlertMessage('Column Name cannot be empty.', 'danger'));
    } else if (isSameColumnName) {
      this.isColumnError = true;
      this.messageService.add(new AlertMessage('Column Names cannot be the same.', 'danger'));
    } else {
      this.columnArray[index].label = newColumnName;
    }
    this.columnArray[index].label = newColumnName;
  }

  isLabelDuplicate(label: string, currentIndex: number): boolean {
    if (label.trim() === '') {
      return true; // If label is empty
    }

    for (let index = 0; index < this.columnArray.length; index++) {
      if (index !== currentIndex && this.columnArray[index].label === label) {
        return true; // If duplicate label found at another index
      }
    }
    return false; // No duplicate label found
  }


  removeColumn(index) {
    for (let i = 0; i < this.rows.length; i++) {
      (this.rows.at(i) as FormGroup).removeControl(this.columnArray[index].value);
    }
    const indexToRemove = this.columnArray.indexOf(this.columnArray[index]);
    if (indexToRemove > -1) {
      this.columnArray.splice(indexToRemove, 1);
    }
  }

  disable(): boolean {
    if (this.columnArray.some(x => { return x.label === '' })) {
      return true;
    } else {
      return false;
    }
  }

  disableMenuAndSave(): boolean {
    const headings = this.rows.value
      .filter(row => (row.label === null || row.label === "" || row.label === "heading") && row.order !== 1)
      .map(row => row.heading);
  
    const questions = this.rows.value
      .filter(row => row.label !== null && row.label !== "" && row.label !== "heading")
      .map(({ heading, ...rest }) => rest);
  
    const hasEmptyHeading = headings.some(heading => !isNullOrUndefined(heading) ? !heading.trim() : !heading);
    const hasEmptyQuestion = questions.some(question =>
      Object.values(question).some(value =>
        value === null || value === 'Select Response' || String(value).trim() === ''
      )
    );
  
    return hasEmptyHeading || hasEmptyQuestion || this.isColumnError;
  }

  removeRow(index) {
    this.rows.removeAt(index);
    this.setOrderNumber(this.orderNumber);
  }

  initGroup(index, row) {
    this.rows.insert(index + 1, this.buildRows());
    this.setOrderNumber(this.orderNumber);
    this.toggleMenu(index);
  }


  initHeader(index, row) {
    this.rows.insert(index + 1, this.buildRows(null, 'Add_Header'));
    this.setOrderNumber(this.orderNumber);
    this.toggleMenu(index);
  }

  addFieldSelection(index: number, j): void {
    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.unsubscribeModal();
      })
    );
    const initialState = {
      list: [
        {"filingBlank": this.filingBlank}
      ]
    };
   this.bsModalRef = this.modalService.show(FieldSelectionComponent, {initialState});
    this.bsModalRef.content.event.subscribe(res => {
      let temp:any[] =
      this.formBuilderService.getSelectedDataCallFieldsList()
      .filter(ele=> ele!= this.rows.at(index).get(j).value);
       temp.push(res.data);
      this.formBuilderService.setSelectedDataCallFieldsList(temp);
      this.setFieldSelectionValue(res.data, index, j);
    });
  }

  addDisabledField(index: number, j, value: string) {
    let temp:any[] =
    this.formBuilderService.getSelectedDataCallFieldsList()
    .filter(ele=> ele!= this.rows.at(index).get(j).value);
    this.formBuilderService.setSelectedDataCallFieldsList(temp);
    this.setFieldSelectionValue(value, index, j);
  }

  setFieldSelectionValue(res, index, j) {
    this.ti = res
    this.rows.at(index).get(j).patchValue(res);
  }

  getFilingBlankLayout() {
    this.formBuilderService.loading.next(true);
    this.formBuilderService.getFilingBlankLayout(this.user.currentSchema).subscribe(
      (res) => {
        if (res.status === 200) {
          this.filingBlank = res.body;
         (this.filingBlank.sections.length !== 0) ? this.formBuilderService.sideNavTitle.next('Page Navigation') : 'Set Page Navigation';
          this.formBuilderService.setFilingBlank(this.filingBlank);
          this.formBuilderService.setOriginalFilingBlank(this.filingBlank);
          this.checkIfEmptyFilingBlank();
          this.checkForSingleSection();
          this.formBuilderService.loading.next(false);
        } else {
          this.filingBlank = [];
          this.formBuilderService.sideNavTitle.next('Set Page Navigation');
          this.formBuilderService.setFilingBlank(this.filingBlank);
          this.formBuilderService.setOriginalFilingBlank(this.filingBlank);
          this.formBuilderService.isFilingBlankEmpty.next(true);
          this.formBuilderService.loading.next(false);
        }
      }, (err) => {
        this.formBuilderService.loading.next(false);
      }
    )
  }

  checkForSingleSection() {
    (this.filingBlank.sections.length === 1) ? this.formBuilderService.initialSectionName.next(this.filingBlank.sections[0].name) : this.formBuilderService.initialSectionName.next('');
  }

  checkIfEmptyFilingBlank() {
    (this.filingBlank.sections.length === 0) ? this.formBuilderService.isFilingBlankEmpty.next(true) : this.formBuilderService.isFilingBlankEmpty.next(false);
  }

  getClassNames(index): string[] {
    if (index === 0) {
      return ['form-group row setWidthForHeading']
    } else if (index === 1) {
      return ['form-group row noIndexWidth']
    } else if (index === 2) {
      return ['form-group row setWidth']
    } else {
      return ['form-group row']
    }
  }

  placeHolder(index): string {
    if (index === 0) {
      return 'Heading';
    } else if (index === 1) {
      return 'No.'
    } else if (index === 2) {
      return 'Enter Question Text'
    } else {
      return 'Enter Value'
    }
  }

  saveFilingBlank() {
    this.messageService.clear();
    let emptyName = this.formBuilderService.getSubMenuItem(this.formBuilderService.dataChange.getValue(), '');
    let filingBlank: FilingBlank = this.formBuilderService.setFormModel(this.addForm, this.columnArray);
    if (emptyName) {
      this.formBuilderService.showErrorMessages.next(true);
      let message = 'Section Name cannot be empty';
      this.messageService.add(new AlertMessage(message, 'danger'));
    } else {
      this.formBuilderService.showErrorMessages.next(false);
      this.formBuilderService.loading.next(true);
      let filingBlank = this.formBuilderService.getFilingBlank();
      if(!this.formBuilderService.isDeleteConfirmed.getValue())this.resetSelectedFilingBlank(filingBlank);
      this.formBuilderService.putFilingBlankLayout(this.schema, filingBlank)
        .subscribe(res => {
          this.formBuilderService.setChangeStatus(false);
          let messageText = `${this.schema} Form Builder has been updated.`;
          this.messageService.add(new AlertMessage(messageText, 'success'));
          this.formBuilderService.isFormUpdated = false;
          if(this.isPasteSectionEnabled) {this.isPasteSectionEnabled = false; this.isCopySectionEnabled = true}
          this.formBuilderService.setNewSectionKey('', '', [new FormSection()]);
          this.formBuilderService.isFilingBlankEmpty.next(false);
          this.formBuilderService.disableDependencyPage.next(false);
          this.formBuilderService.isDeleteConfirmed.next(false);
          this.getFilingBlankLayout();
          this.checkForSingleSection();
          this.formBuilderService.loading.next(false);
        });
      window.scroll(0, 0);
      let myDiv = document.getElementById('form-builder:build-edit-form');
      myDiv.scrollTop = 0;
    }
  }

  isCopyOrPasteEnabled() {
    if (this.formSection.questions.length === 0 || isNullOrUndefined(this.formSection)) {
      this.isCopySectionEnabled = false;
      this.isPasteSectionEnabled = true;
    } else {
      this.isPasteSectionEnabled = false;
      this.isCopySectionEnabled = true;
    }
  }

  isPasteDisabled(): boolean {
    if (!this.formBuilderService.getCopiedSection()) {
      return true;
    } else {
      return false;
    }
  }

  copySection() {
    this.formBuilderService.setCopiedSection(this.selectedFormSection.questions);
    this.formBuilderService.setCopiedSectionHeaders(this.selectedFormSection.headings);
  }

  pasteSection() {
    let filingBlank = this.formBuilderService.getFilingBlank();
    this.formBuilderService.setNewSectionKey(this.selectedFormSection.key, 'build-edit-form', filingBlank.sections);
    this.setCopiedSections(filingBlank.sections);
    this.buildForm();
    let filteredArray: UiColumnModel[] = this.columnArray.filter(item => item.value !== "label" && item.value !== "text" && item.value !== "heading" && item.value !== "order");
    (this.addForm.get('rows') as FormArray).controls.forEach((control, index) => {
      filteredArray.forEach(x=> {
        control.get(x.value).patchValue('Select Response');
      });
    });
  }

  setCopiedSections(filingBlank: FormSection[]) {
    const copiedSection = this.formBuilderService.getCopiedSection();
    const firstSectionInputKeys = copiedSection[0].inputs.map(() => uuidv4());

    for (let i = 0; i < copiedSection.length; i++) {
      copiedSection[i].inputs.forEach((input, index) => {
        input.key = firstSectionInputKeys[index];
      });
    }
    //set key values to null
    copiedSection.forEach((item) => {
      item.key = null;
    });
    this.formBuilderService.setCopiedSection(copiedSection);
    filingBlank.forEach(x => {
      if(x.displayKey === this.selectedFormSection.displayKey) {
        x.questions = this.formBuilderService.getCopiedSection();
        x.headings = this.formBuilderService.getCopiedSectionHeaders();
      }
      if(x.sections.length>0) {
        this.setCopiedSections(x.sections);
      }
    });
  }

  pasteSectionModal(template: TemplateRef<any>) {
    if(this.formBuilderService.isFormUpdated) {
      this.bsModalRef = this.modalService.show(template, {class: 'modal-lg'});
    } else {
      this.pasteSection();
    }
  }

  resetSelectedFilingBlank(filingBlank) {
    const allSections =filingBlank.sections;
    const key = this.formSection.key;
    const findSectionByKey = (sections: any[], key: string): any | null => {
      for (const section of sections) {
        if (section.key === key) {
          return section;
        }
        if (section.sections && section.sections.length > 0) {
          const foundSection = findSectionByKey(section.sections, key);
          if (foundSection) {
            return foundSection;
          }
        }
      }
      return null;
    };
    this.removeNullAndUndefined(filingBlank.sections);
    this.selectedFormSection = findSectionByKey(allSections, key);
    // this.buildForm();
  }

  removeNullAndUndefined(formSections: any[]) {
    formSections.forEach(x => {
      if (isNullOrUndefined(x.order)) {
        delete x.order;
      }
      if(x.sections.length > 0) {
        this.removeNullAndUndefined(x.sections);
      }
    });
  }

  toggleMenu(index: number) {
    this.menuStates[index] = !this.menuStates[index];
  }
 
  hasHeading(row: FormGroup) {
    return (row.get('label').value === 'heading');
  }

  isDeleteHidden(row: FormGroup): boolean {
    if(row.get('order').value === 1 || row.get('order').value === 2) {
      return false;
    } else {
      return true;
    }
  }

  cancel() {
    this.formBuilderService.isDisableAddEditButton.next(false);
    this.formBuilderService.disableDependencyPage.next(false);
    this.formBuilderService.setChangeStatus(false);
    this.getFilingBlankLayout();
    this.selectedFormSection = undefined;
    this.formBuilderService.isFormUpdated = false;
    this.formBuilderService.setClickedName('');
  }

  private unsubscribeModal(): void {
    this.modalSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.modalSubscriptions = [];
  }

}
